.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

a.selectBox {
  &.error {
    border: 1px solid $color-red;
  }
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.site-footer {
  .site-footer-contact__item {
    &--phone {
      width: 100%;
    }
    &--email {
      width: 100%;
    }
  }
  .site-footer__main {
    position: relative;
    border-bottom: 1px solid #cccccc;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: left;
    .site-footer__column:nth-child(2) {
      padding-top: 25px;
    }
  }
}

.lp_pagination-area {
  .lp_buttons_area {
    .lp_next_button {
      background: $color-black !important;
    }
  }
}

.customer-service-section {
  div {
    display: block;
  }
}

.mantle-custom-text {
  span,
  p {
    font-family: $bb-sans-n !important;
  }
}

.gnav-section {
  &__link-wrapper {
    &__inner {
      font-family: $bb-sans-n !important;
    }
  }
  &__link {
    font-family: $bb-sans-n !important;
  }
}

.tabbed-products-block {
  &__tab {
    font-family: $bb-sans-n !important;
  }
}

.shade-finder-block {
  &__filter {
    font-family: $bb-sans-n !important;
  }
}
