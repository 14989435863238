$baseMobileWidth: 1em;
//Control directive to highlight checkout breadcrumb
.checkout--single-page {
  $checkout: (shipping, review, billing, confirm);
  @each $value in $checkout {
    &.#{$value} {
      .checkout-progress .#{$value} {
        font-weight: bold;
        a {
          color: $color-pink;
          &:hover,
          &:visited {
            color: $color-pink;
          }
        }
        &:before {
          background: $color-pink;
          color: $color-pink;
        }
      }
    }
  }
}

#checkout_signin_new_user {
  label {
    display: inline-block;
    visibility: visible;
  }
  .new-account__fieldset {
    .form-item {
      .form_label {
        .label {
          display: inline-block;
          visibility: visible;
        }
      }
      input,
      #sms_request_pin,
      #sms_verify_pin {
        margin-top: 5px;
      }
      #form--checkout_signin_new_user--field--PASSWORD {
        margin-bottom: 0px;
      }
    }
    .form-item.mobile_number_field {
      width: 100%;
      select {
        min-width: 29.9%;
        margin-bottom: 0px;
        @supports (height: calc(3em - -2px)) {
          height: calc(3em - -2px);
        }
        @supports not (height: calc(3em - -2px)) {
          height: 3em;
        }
      }
      select,
      input {
        display: inline;
      }
      input {
        width: 75px;
        min-width: 29%;
      }
    }
    .terms_agree {
      .form-block {
        margin-top: 20px;
      }
    }
    .all_term_accept,
    .age_restriction {
      margin-top: 20px;
    }
    .signin_submit {
      margin-top: 20px;
    }
  }
  .new-account__button.sign-in-page__submit {
    margin-top: 10px;
  }
}

.checkout {
  margin-left: 20px;
  margin-right: 20px;
  &-signin-mobile {
    margin: 0;
  }
  #offer-code-panel {
    height: auto;
    padding-bottom: 0px;
    margin: 20px 0 0 0;
    input[type='submit'] {
      margin-top: 20px;
    }
  }
  ol.checkout-progress {
    margin: 0 10px 0 0;
  }
  .checkout__content {
    clear: both;
    .offer-code-panel {
      background: $color-gray-background;
      padding: 16px 20px;
    }
    .viewcart-panel__title,
    .checkout__panel-title,
    .checkout__subtitle {
      @include krfont-bold();
    }
  }
  .mobile-breadcrumb {
    display: block;
    li {
      display: list-item;
    }
  }
  .checkout__panel-content {
    .sub__total {
      float: right;
      margin-top: 10px;
    }
  }
  .cart-item {
    .name a,
    .sub-name a {
      text-transform: uppercase;
    }
    .error {
      color: $color-red;
    }
  }
  .address_controls {
    margin: 10px 0;
  }
  #confirmation-number {
    margin: 10px 0;
  }
  #confirmation-email {
    margin-bottom: 10px;
  }
  .cart-item__swatch {
    margin-left: 0;
  }
  #continue-checkout-btn-element {
    width: 100%;
  }
  .return-link-container {
    width: 100%;
    float: left;
  }
  .print-buttons {
    width: 100%;
    float: left;
  }
  #confirmation-page {
    padding-top: 10px;
    #sidebar {
      padding: 0px;
    }
  }
  .recommended-products-panel {
    display: block;
    .recommended-item {
      .product_name a,
      .product_subname a,
      .cart-item__size,
      .shade,
      .formatted_price {
        font-size: 14px;
      }
      input[type='submit'] {
        padding: 1em;
        letter-spacing: normal;
        font-size: 11px;
        &.btn {
          width: auto;
        }
      }
    }
    .recommended-products-panel__title {
      @include krfont-bold();
    }
    .recommended-products-panel__title:after,
    .recommended-products-panel__title:before {
      width: 47px;
    }
    .recommended-product-items {
      .recommended-item {
        img {
          width: auto;
        }
      }
      .cart-item__desc-info {
        min-height: 0;
        .product_name {
          min-height: 0;
          a {
            border: 0;
          }
        }
        .color {
          padding-bottom: 10px;
        }
      }
    }
  }
  .cross_sell_container {
    .cart_cross_sell_item {
      float: left;
      margin: 0.4em 1.2em 0.8em 0;
      position: relative;
      width: 100%;
      input[type='submit'] {
        font-size: 10px;
        padding: 10px 30px;
      }
    }
  }
  .order_status {
    header {
      display: block;
    }
  }
  .cart-items {
    border-bottom: 1px solid $color-light-gray;
    .cart-item {
      .border {
        border: 1px solid $color-white;
      }
    }
    .sub__total {
      margin: 0.8em 0;
      float: right;
    }
    .free_sample {
      font-weight: normal;
      text-transform: capitalize;
    }
  }
  .continue-buttons {
    .disabled {
      background-color: $color-light-gray;
    }
    a.disabled:hover {
      background-color: $color-light-gray;
    }
  }
  .gift_form_container {
    margin-left: 20px;
  }
  .total_column {
    float: right;
    margin: 0.8em 0;
  }
  .signin-to-see-cart a {
    @include krfont-bold();
    color: $color-red;
  }
}

.shipping-edit-address-content__content {
  label {
    display: inline;
  }
  #address_form_container {
    border: none;
    padding: 0;
  }
}

#shipping-panel {
  .gift-options-display,
  .shipping-address-display {
    margin: 0;
  }
  a.btn-mini {
    top: 0;
    right: 0;
  }
}

.ui-autocomplete {
  overflow-x: auto;
  height: 13em;
}

ul.error {
  color: $color-red;
}

.order-confirmation {
  a.order-details-page__link {
    display: none;
  }
}

.order-info {
  p {
    margin-bottom: 10px;
  }
}

.order-details {
  div.order-details__item {
    margin-bottom: 15px;
  }
}

.trans_detail_item {
  margin-bottom: 1.4em;
}

.samples-page {
  .product {
    .samples-buttons,
    .sample-select-checkbox,
    .samples_prod_select {
      display: block !important;
      visibility: visible !important;
    }
    .samples_prod_select:hover {
      background-color: $color-black !important;
      border-color: $color-black;
    }
  }
  .samples-panel__title {
    @include krfont-bold();
  }
}

#viewcart {
  .messages {
    background: none;
    border: medium none;
    margin: 0;
    padding: 0;
  }
  .checkout {
    &__sidebar {
      .order-summary-panel {
        display: block !important;
        visibility: visible;
      }
    }
  }
}

.order-summary__table {
  th,
  td {
    border: none;
  }
}

.cart-item__price {
  float: none;
  width: auto;
}

.cart-item__remove-form {
  float: right;
}

.cart-item-rows,
.cart-item {
  border-top: 1px solid $color-gray-border;
  clear: left;
  min-height: 100px;
  padding: 10px 0;
  position: relative;
}

.cart-item-rows:first-child {
  border: none;
}

#shipping {
  .address-form-container {
    .phone_container {
      .phone_part_area_code,
      .phone_part {
        width: 30%;
        float: left;
        select,
        input[type='text'] {
          width: 100%;
          height: 32px;
          min-width: 80px;
        }
        &.phone1_1,
        &.phone1_2,
        &.phone2_1,
        &.phone2_2 {
          margin-left: 5%;
        }
      }
    }
  }
  .shipping-address {
    #choose-address {
      .address-select {
        height: 44px;
      }
    }
    #delivery-instructions {
      a {
        margin-top: 10px;
        display: inline-block;
      }
    }
    input[type='text'] {
      margin-bottom: 0px;
    }
    .form_element {
      margin-bottom: 15px;
    }
    .phone_container {
      .phone_part_area_code {
        select {
          height: 44px;
        }
      }
    }
  }
  .address {
    .state_container {
      margin: 20px 0;
    }
  }
  .address_controls {
    .field {
      float: left;
      width: 50%;
    }
  }
  #please-note-changes,
  #delivery-options {
    margin: 10px 0;
  }
  #delivery-info p {
    margin: 10px 0 0 0;
  }
  #profile-postal {
    input {
      width: 45%;
      margin-right: 10px;
    }
    width: 50%;
    float: left;
    input[type='text'] {
      width: 100%;
    }
  }
  .lookup_submit_note {
    float: left;
    position: relative;
    margin: 0;
  }
  .address_lookup_submit {
    width: 45%;
    float: right;
    line-height: 2em;
    height: 44px;
  }
  .required-message {
    margin-bottom: 15px;
  }
}

.gift-options-wrapper {
  margin: 10px 0;
  a.edit-gift-invoice-address {
    float: right;
  }
}

#payment-container {
  .form-item {
    float: left;
    width: 50%;
    margin: 10px 0;
  }
}

#vbank-payment-alert {
  color: $color-red;
  margin-top: 20px;
  display: none;
}

.payment-info {
  margin-top: 15px;
}

#review-address-info {
  a.grey_button {
    float: right;
    margin-right: 10px;
  }
}

.trans_detail_left {
  border-right: 1px solid $color-black;
  float: left;
  height: 200px;
  margin-right: 15px;
  width: 50%;
}

#review {
  .trans_detail_item h4 {
    font-size: 1.1em;
    font-weight: bold;
    padding-top: 10px;
  }
  #review-instructions {
    margin-top: 10px;
  }
  #gift-info {
    display: none;
  }
  .gift-order {
    #gift-info {
      display: block;
    }
    #no-gift-info {
      display: none;
    }
  }
  .gift-item-info {
    margin-top: 15px;
  }
  #gift-invoice-desc {
    font-weight: bold;
    padding-top: 10px;
  }
}

.address-container {
  font-style: normal;
}

.optional-info {
  .birthday {
    select {
      min-width: 80px;
      width: 100%;
      padding: 0 25px 0 5px;
    }
    .optional-info__item {
      width: 32%;
      margin: 0 1% 5px 0;
      padding: 10px 10px 10px 0px;
    }
  }
}

.signin-wrapper {
  .new-account-section,
  .return-user-section {
    input[type='text'],
    input[type='password'] {
      display: block;
      width: 100%;
    }
  }
  .new-account__item,
  .return-user__item {
    margin-bottom: 20px;
  }
}

#links-panel {
  margin-top: 20px;
  .content {
    .link {
      border: 0px;
      width: 100%;
    }
  }
  .checkout__panel-title {
    &.links-panel__title {
      margin-top: 10px;
    }
  }
}

.confirmation-panel {
  padding-bottom: 0px;
}

#cboxWrapper {
  #customer-service {
    .customer-service-header {
      padding: 1em 0px;
    }
    .customer-service-content {
      padding-top: 10px;
      p {
        padding: 3px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  ol.checkout-progress li {
    font-size: 13px;
  }
}

.promo_thanks_wrapper {
  padding: 20px 20px 0px;
  #sidebar {
    padding-top: 10px;
    .customer-service-header {
      padding: 0px 0px 5px !important;
    }
  }
}

.checkout-progress {
  .checkout-progress-bar__list-item {
    width: 25%;
  }
}

.viewcart,
.samples,
.confirm,
.opc__wp_samples,
.shipping,
.review,
.checkout_signin_content {
  .site-utils {
    &__menu {
      display: none;
    }
    &__icon--marker {
      display: none;
    }
  }
  .sticky-offer {
    display: none !important;
  }
  .site-footer {
    margin-top: 10%;
    &__main,
    &-contact {
      display: none;
    }
    .foot_copy {
      margin-bottom: 6em;
    }
  }
  .opc__footer {
    display: none;
  }
  .bb_copyright {
    padding: 25px 20px 4em 20px;
    line-height: 20px;
  }
}

.shipping,
.review,
.checkout_signin_content {
  .site-utils {
    &__item--search,
    &__item--cart,
    &__icon--hamburger,
    &__item--stores,
    &__icon--search {
      display: none;
    }
  }
  .mobile-checkout-back {
    display: block;
    .icon {
      font-size: 39px;
      position: absolute;
      #{$ldirection}: 12px;
      top: 10px;
    }
  }
}

.form_address__group {
  .address1_container {
    width: 50%;
    float: left;
    margin: 0 12px 0 0;
  }
  .address2_container {
    width: 45%;
    float: right;
    display: inline-block;
  }
  input[type='text'] {
    margin-bottom: 0px;
  }
}

.order-summary-panel {
  #order_status {
    .order-summary-panel__title {
      border-bottom: 1px solid $color-black;
    }
  }
}

.adpl,
#password_reset {
  .new-account__fieldset,
  .return-user__fieldset,
  .profile-info__fieldset,
  .address,
  .password-reset__fieldset,
  .address-form__fieldset,
  .password-reset {
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    input[type='text'],
    select {
      &.js-label-mode + label:before {
        content: attr(data-required) attr(alt);
      }
      + label:before {
        content: attr(data-required) attr(placeholder);
      }
    }
  }
}

.address-book-page {
  .address-form {
    .form-item {
      &.phone_1,
      &.phone_2 {
        span {
          &.label-content {
            display: inline-block;
          }
        }
      }
    }
  }
}

#checkout_shipping {
  &.adpl {
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    input[type='text'],
    select {
      border-color: $color-black;
      &:focus {
        border-color: $color-pink;
      }
      &.error {
        border-color: $color-red;
      }
    }
  }
}

#viewcart,
#samples,
#shipping,
#review,
#payment,
.samples-page,
.checkout {
  h2.checkout__panel-title {
    font-size: 14px;
  }
}
