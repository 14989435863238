$brandon-text: 'Nanum Gothic', sans-serif;
$brandon-text-bold: 'Nanum Gothic', sans-serif;

$font_updates: true;
// Enable Appointment Booking Reskin 2019
$ab_skin_v2: true;
$bb-sans-n: 'BBSans Regular', 'Nanum Gothic', sans-serif !default;

$cr22: true;

$cr24: true;
