.change-password {
  .profile-password-update {
    &__fieldset {
      width: 90%;
      .form-item  {
        width: 80%;
      }
    }
    &__rules {
      width: 80%;
    }
  }
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($medium-up) {
        position: absolute;
        #{$rdirection}: 30%;
        top: 29%;
        transform: translateY(-50%);
        width: 36%;
        border: 1px solid $color-gray;
        margin-#{$rdirection}: 3%;
        &:before {
          @include breakpoint($medium-up) {
            content: '';
            position: absolute;
            top: 32%;
            #{$ldirection}: -5%;
            border: 5px solid transparent;
            border-#{$rdirection}-color: $color-gray;
            -ms-transform: rotate(0);
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }
        }
      }
      @include breakpoint($medium-portrait-only) {
        #{$rdirection}: 100%;
        width: 54%;
        margin-#{$rdirection}: 2%;
      }
      &-reset {
        bottom: auto;
        top: 20%;
        #{$ldirection}: 23%;
        @include breakpoint($medium-up) {
          width: 250px;
          top: 75%;
          #{$ldirection}: 26%;
        }
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 32%;
        }
      }
    }
    &__rules {
      width: 60%;
      clear: both;
    }
  }
}
