.account {
  &__return-user,
  &__new-account {
    input[type='email'] {
      width: 100%;
      display: block;
      margin-top: 10px;
    }
  }
  &-mobile-landing-page {
    padding: 20px;
  }
  &__section {
    margin: 0px !important;
    .account-landing-profile__header,
    .profile-page__header,
    .optional-info__header {
      @include krfont-bold();
    }
    .profile-info {
      h3 {
        @include krfont-bold();
      }
    }
    .optional-info {
      .email_promotions {
        .promotions_label {
          width: 135px;
          font-weight: bold;
          display: inline-block;
          margin-bottom: 10px;
        }
      }
    }
    .address-book-page__content {
      .address-book-page__header,
      .payment-info__header {
        @include krfont-bold();
      }
    }
  }
  .address-book-page {
    .address-form__item {
      &.form-item-postal {
        .address-form-postal {
          width: 45%;
          float: left;
          .input_postal_code {
            width: 100%;
          }
        }
        .address_lookup_submit {
          height: 43px;
          line-height: 2em;
        }
      }
      &.address1_container {
        width: 45%;
        padding-right: 0;
      }
      &.address2_container {
        width: 50%;
        padding: 10px 10px 10px 0;
      }
    }
  }
  .main_container {
    .account__section {
      padding: 20px;
    }
  }
}

#membership_withdrawal {
  .profile-info {
    .margin_top_bottom {
      select {
        width: 250px;
      }
    }
  }
}

.past-purchases-page {
  .past-purchases-page__header {
    @include krfont-bold();
  }
}

.favorites-page {
  .favorites-page__header {
    @include krfont-bold();
  }
}

.password-request-page {
  &__header {
    line-height: 32px;
  }
}

.password-sent-page {
  .sent-info {
    .sent-info__text {
      margin: 10px 0px;
    }
  }
}

.password-reset-page {
  .password-reset__item {
    margin: 10px;
    input {
      width: 100%;
    }
  }
  .password-reset__text {
    margin: 10px;
  }
  .password-reset__submit {
    margin-left: 10px;
  }
  &__header {
    line-height: 32px;
  }
}

#registration {
  .account-mobile-landing-page {
    .profile-page {
      .full_name_container {
        margin-top: 10px;
        input[type='text'] {
          width: 100%;
          margin-bottom: 0px;
        }
      }
      .profile-info__item {
        padding-left: 0px;
        padding-right: 0px;
      }
      .profile-info__text {
        padding: 20px;
      }
      .mobile_number {
        float: left;
        padding-top: 10px;
        padding-bottom: 10px;
        select {
          height: 44px;
          min-width: 29.9%;
          @media only screen and (max-width: 320px) {
            min-width: 27.9%;
          }
        }
        input[type='text'],
        input[type='tel'] {
          width: 29%;
        }
      }
      .mobile_verify {
        width: 100% !important;
        margin-top: 20px;
        .form_input input[type='text'] {
          float: left;
          width: 50%;
        }
        #sms_verify_pin {
          float: right;
        }
        .form_label {
          float: left;
        }
      }
      .big-button {
        margin-left: 0px !important;
      }
    }
  }
  .newsletter-info__text {
    padding: 0 20px;
    margin: 0;
  }
  .required_fields {
    padding: 5px;
  }
  .terms_required_fields {
    margin: 20px;
  }
  .address_static_block {
    margin-left: 10px;
  }
  .when_is_your_birthday {
    label {
      margin-left: 0px;
    }
    h3 {
      font-weight: bold;
    }
  }
  .optional-info__item {
    margin-left: 0px;
  }
}

#registration_short {
  label {
    display: inline-block;
    visibility: visible;
  }
  .new-account__fieldset {
    .form-item {
      .form_label {
        .label {
          display: inline-block;
          visibility: visible;
        }
      }
      input,
      #sms_request_pin,
      #sms_verify_pin {
        margin-top: 10px;
      }
      #sms_request_pin {
        width: 95%;
      }
      #sms_verify_pin {
        width: 100%;
      }
      #form--registration_short--field--PASSWORD {
        margin-bottom: 0px;
      }
    }
    .form-item.mobile_number_field {
      width: 100%;
      select {
        min-width: 29.9%;
        margin-bottom: 0px;
        height: 44px;
      }
      select,
      input {
        display: inline;
      }
      input {
        width: 29%;
      }
    }
    .terms_agree {
      width: 72%;
      .form-block {
        margin-top: 20px;
      }
      .label.error {
        color: $color-red;
      }
    }
    .disagree_message_text,
    .marketing_notes,
    .promotion_notes,
    .age_restriction {
      width: 72%;
    }
    .all_term_accept,
    .age_restriction {
      margin-top: 20px;
      .label.error {
        color: $color-red;
      }
    }
    .signin_submit {
      width: 72%;
      margin-top: 20px;
    }
  }
  .new-account__button.sign-in-page__submit {
    margin-top: 10px;
  }
  .password {
    input[type='text'],
    input[type='password'] {
      width: 60%;
      float: left;
    }
    label {
      padding-top: 10px;
      padding-left: 5px;
    }
    .pwd_msg {
      font-weight: bold;
      color: $color-red;
      margin-top: 10px;
      display: inline-block;
    }
  }
}

.new-account__fieldset .form-item #sms_request_msg {
  display: inline-block;
}

.lookup_submit_note {
  margin: 10px;
  position: absolute;
}

.shipping-billing {
  .address-lookup {
    padding: 0 10px;
  }
  .address-form {
    margin-top: 15px;
    .address-form__item {
      select {
        min-width: 30%;
      }
      submit {
        float: right;
      }
    }
  }
}

.phone_container {
  &.phone_1,
  &.phone_2 {
    .phone_part {
      width: 30%;
      float: left;
      select,
      input[type='text'] {
        width: 100%;
        @supports (height: calc(3em - -2px)) {
          height: calc(3em - -2px);
        }
        @supports not (height: calc(3em - -2px)) {
          height: 3em;
        }
      }
      &.phone1_1,
      &.phone1_2,
      &.phone2_1,
      &.phone2_2 {
        margin-left: 5%;
      }
    }
  }
}

.order-details__item {
  h4 {
    font-weight: bold;
  }
}

.order_total {
  float: left;
  margin-right: 23px;
}

.address-form__item {
  label.error {
    border: none;
  }
}

#add_address,
#edit_address {
  .address-header {
    h1 {
      float: left;
    }
    .grey_button {
      float: right;
      margin-right: 10px;
    }
  }
  .required_field_notice {
    margin-left: 10px;
  }
  #profile-postal,
  .form-item-postal {
    input {
      width: 60%;
    }
  }
  .lookup_submit_note {
    float: left;
    position: relative;
    margin: 0;
  }
  .address_lookup_submit {
    width: 34%;
  }
  .grey_button {
    margin-left: 10px;
    padding: 9px;
    text-decoration: none;
    float: right;
  }
  .address-form__item {
    span {
      display: block;
      float: left;
    }
    label {
      padding-right: 5px;
      width: 90%;
      float: left;
    }
  }
  .phone_container,
  .address-form__item {
    padding: 10px;
    span {
      display: block;
      float: left;
    }
    label {
      padding-right: 5px;
      width: 90%;
      float: left;
    }
    select {
      padding: 0px 10px;
    }
    &input_1_1,
    &input_1_2,
    &input_2_1,
    &input_2_2 {
      width: 40%;
      float: right;
      margin-left: 2%;
    }
  }
  .default-shipping-checkbox {
    padding: 10px;
  }
  .address-form {
    input[type='text'] + label {
      float: none;
      span.label-content {
        display: none;
      }
    }
    .address-form-postal {
      width: 50%;
      float: left;
      .input_postal_code {
        width: 100%;
      }
    }
    .address_lookup_submit {
      width: 45%;
      height: 44px;
      line-height: 2em;
    }
    .PHONE1_AREA_CODE,
    .PHONE2_AREA_CODE {
      height: 44px;
    }
    .form_address__group {
      .address1_container {
        padding-right: 0px;
      }
      .address2_container {
        padding-left: 0px;
      }
    }
    label {
      .label-content {
        display: inline-block;
      }
    }
  }
  #address {
    &.adpl {
      input[type='email'],
      input[type='tel'],
      input[type='password'],
      input[type='text'],
      select {
        border-color: $color-black;
        &:focus {
          border-color: $color-pink;
        }
        &.error {
          border-color: $color-red;
        }
      }
    }
  }
}

#return-address {
  .address-form-container {
    input {
      width: 100%;
    }
    .form-item-postal {
      input {
        width: 40%;
        margin-right: 10px;
      }
    }
  }
  .phone_container {
    select {
      padding: 5px 0px;
      float: left;
      margin-right: 2%;
      min-width: 65px;
    }
    input[type='text'] {
      width: 33% !important;
      float: right;
      &.order-phone1-part1,
      &.order-phone2-part1 {
        float: left;
        margin-right: 2%;
      }
    }
  }
}

.address-book-page {
  .address-book-page__content {
    .messages {
      display: none;
    }
  }
  .address-book {
    width: 88%;
    .section-head {
      @include krfont-bold();
      font-size: 12px;
    }
  }
  .payment-info {
    width: 88%;
  }
  .address-form__item {
    &.form-item-postal .field {
      width: 40%;
      margin-right: 10px;
    }
    .grey_button {
      width: 50%;
      margin-left: 10px;
      padding: 9px;
      text-decoration: none;
      float: right;
    }
  }
}

.order-details-page__content {
  #cart_items {
    .cart-item-rows {
      .cart-item__total,
      .total {
        float: right;
        margin-top: 8px;
      }
    }
    h4 {
      @include krfont-bold();
    }
    .cart-item {
      &__qty {
        &-label {
          @include breakpoint($small-down) {
            float: #{$ldirection};
            margin-#{$rdirection}: 7px;
          }
        }
      }
    }
  }
}

#past_purchases {
  .past-purchases__content {
    margin: 40px 0;
    .outer-wrap {
      margin: 0 10px;
      .past-purchases__shopping {
        margin-left: 0;
      }
    }
  }
}

.order-details {
  .order-details-page__content {
    #cart_items {
      .cart-item__desc-container {
        float: none;
      }
    }
  }
}

.sign-in-page {
  &--mobile label {
    .return-user__item {
      display: block;
      visibility: visible;
    }
  }
  &.sign-in-page--mobile {
    label,
    .required_mark,
    .required,
    .return-user__label {
      display: block;
      visibility: visible;
    }
    .account__return-user {
      span {
        float: left;
      }
      .required {
        float: none;
        padding-bottom: 10px;
      }
      span.show span {
        float: none;
      }
    }
    .account__new-account {
      .required_mark {
        float: left;
      }
      .new-account__text {
        margin-top: 10px;
      }
      .optional-info {
        &__header {
          font-size: 15px;
          font-weight: bold;
        }
        &__fieldset {
          width: 75%;
        }
      }
    }
  }
}

.address {
  .address-form__item label {
    color: $color-black;
    border: none;
  }
}

.profile-info {
  .profile_header_wrapper {
    padding: 0px !important;
  }
}

#signin {
  .account__new-account input[type='password'],
  .account__new-account input[type='email'],
  .account__return-user input[type='password'],
  .account__return-user input[type='email'] {
    margin-bottom: 0px;
  }
  .sticky-offer {
    display: none !important;
  }
  .opc__footer {
    display: none;
  }
  .show {
    float: inherit;
    margin-top: 3%;
    display: inline-block;
  }
  #registration_short,
  .sign-in-page {
    .new-account__fieldset {
      .full_name,
      .email,
      .password,
      .mobile_pin_field {
        &.form-item {
          margin-bottom: 15px;
          width: 72%;
        }
        label {
          display: block;
        }
      }
      .mobile_number_field {
        width: 75%;
        a.selectBox {
          height: 44px;
          width: 30%;
          margin: 10px 1% 10px 0;
          float: left;
          line-height: 3;
          .selectBox-arrow {
            line-height: 3;
          }
        }
      }
      .mobile_pin_field {
        .form_label {
          .label {
            display: block;
          }
        }
      }
      .password {
        .q_password + label {
          width: 60%;
          float: left;
          padding-top: 0px;
        }
      }
    }
  }
}

#profile_preferences,
#signin,
#registration_short {
  &.adpl {
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    input[type='text'],
    select {
      border-color: $color-black;
      &:focus {
        border-color: $color-pink;
      }
      &.error {
        border-color: $color-red;
      }
    }
  }
}
