/* font-style : ³ª´®°íµñ */
@font-face {
  font-family: 'Nanum Gothic bold';
  src: url('#{$netstorage-fonts-path}cjk/NanumGothic/NanumGothic-Bold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Nanum Gothic';
  src: url('#{$netstorage-fonts-path}cjk/NanumGothic/NanumGothic-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Nanum Gothic extra bold';
  src: url('#{$netstorage-fonts-path}cjk/NanumGothic/NanumGothic-ExtraBold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}

* {
  font-family: 'Nanum Gothic', sans-serif;
}

@mixin font-gothic {
  font-family: 'Nanum Gothic', sans-serif;
}

body,
div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
input,
form,
dl,
dt,
dd,
label,
small,
select,
article,
strong,
h10,
.site-nav,
button,
textarea,
#navigation,
#footer {
  @include font-gothic;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  @include font-gothic;
}

.top-left,
.top-right,
a.button,
strong,
b,
form label,
fieldset span,
a.btn-add-to-bag {
  @include font-gothic;
}

[class*='icon-'],
.icon,
.selectBox-arrow:before,
.site-nav .menu__link--lvl-1.menu__link--has-children:after,
.site-nav .menu__link--lvl-2.menu__link--has-children:after,
.site-utils__dropdown__close:after,
#cboxClose:before,
.slick-prev:before,
.slick-next:before,
input[type='checkbox'] ~ label:before,
input[type='checkbox'] ~ .label:before,
input[type='checkbox']:checked ~ label:before,
input[type='checkbox']:checked ~ .label:before,
input[type='radio'] ~ label:before,
input[type='radio'] ~ .label:before,
input[type='radio']:checked ~ label:before,
input[type='radio']:checked ~ .label:before,
.bobbis-kit .product-grid__item:nth-child(1):after,
.bobbis-kit .product-grid__item:nth-child(2):after,
.responsive-tabs.responsive-tabs--how_to_use .resp-arrow:before,
.quickshop-wrapper.active:before,
.quickshop__close:before,
.foundation-finder__find-match:after,
.foundation-finder__hide:after,
.foundation-finder__quiz:before,
.foundation-finder__chat__copy:before,
.product-palette:before,
.product-sku-quickview__inner:before,
.offer-specific__container .offer__breadcrumb-link:before,
.makeup-lesson__step .icon-arrow:before,
.artist-picks-page__products .recommended-actions a:before,
.chat-history-page__products .recommended-actions a:before,
.favorites-mylists__lists-create-button:before,
.favorite-actions__link--add:before,
.favorite-actions__link--remove:before,
.my-lists__list-link--print:before,
.my-lists__list-link--email:before,
.giftcard__guides-header:before {
  font-family: 'icons' !important;
}

.site-nav .menu__link--lvl-1,
.site-nav .menu__link--lvl-2,
.site-footer-contact__link,
.site-footer-social-links__header,
.sticky-offer__headline,
.sticky-chat__headline,
.responsive-tabs h2.resp-accordion,
.responsive-tabs h2.resp-tab-active,
.product--full .product__sku-categories-nav-item,
.product-gallery__shade-name,
.quickshop .product--full .product__title,
.quickshop .product--full .product__rating,
.product-filter__item,
.site-footer__main .site-email-signup__header,
.sign-in .account-links__profile,
.sign-in .account-links__orders,
.sign-in .account-links__favorites,
.sign-in .account-links__loyalty,
.sign-in .account-links__login,
.sign-in .account-links__logout,
.mpp__filters__headline,
.spp-mobile-page .spp__share,
.account-profile .section-header__header,
.account-orders .section-header__header,
.account-address .section-header__header,
.account-payment .section-header__header,
.account-loyalty .section-header__header,
.account-mobile-landing-page .account-landing-menu .account-landing-menu__item a,
.address-book-page .address-book__header,
.address-book-page .payment-info__header,
.add-payment-page__header,
.artist-picks-page__products .recommended-products__links-add-all,
.chat-history-page__products .recommended-products__links-add-all,
.artist-picks-page .transcript .transcript__chat--skus .recommended-skus__links-add-all,
.chat-history-page .transcript .transcript__chat--skus .recommended-skus__links-add-all,
.favorites-page__tab,
.wishlist-page__header,
.my-lists__list-header-link,
.address-info__header,
.address-info h3,
.profile-info__header,
.profile-info h3,
.optional-info__header,
.optional-info h3,
.sms-promotions__header,
.sms-promotions h3,
.newsletter-info__header,
.newsletter-info h3,
.h8,
.product-thumb__headline,
.product-thumb--price,
.product-recommendation .product-recommendation__filter-nav > li,
.responsive-tabs .resp-tab-item,
.product--full .product__price,
.product--full .product__rating,
.product--full .product__tip .product__tip-title,
.shade-picker,
.shade-picker__color-name,
.search-form .search-suggestions__link {
  font-family: 'Nanum Gothic', sans-serif;
}
