@mixin font-gothic {
  font-family: 'Nanum Gothic', sans-serif;
}
.section-store-locator {
  .responsive-tabs--store-locator {
    .resp-tab-item:last-child {
      display: none;
    }
  }
}

#customer_service_info {
  .customer_service_content {
    #shipping_handling,
    #sales_tax,
    #returns,
    #max_purchase_policy {
      padding: 20px;
    }
  }
}

.spp-product-layout {
  .sticky-add-to-bag {
    &__ctas-wrapper--non-shaded {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 50%;
      }
    }
  }
}

#footer {
  .site-footer-contact {
    .site-footer-contact__menu {
      margin-top: 8px;
    }
  }
  .country-select {
    padding-left: 20px;
  }
}

.site-footer {
  &__main {
    .trustmark-logo {
      width: 75px;
    }
  }
  &__column {
    .country-select,
    language-select {
      padding-left: 20px;
    }
    .country-select__selectbox selectbox {
      width: 60%;
    }
  }
}

cite:before {
  content: '\2014 \00A0';
}

[class*='icon-'],
.icon,
.selectBox-arrow:before,
.site-nav .menu__link--lvl-1.menu__link--has-children:after,
.site-nav .menu__link--lvl-2.menu__link--has-children:after,
.site-utils__dropdown__close:after,
#cboxClose:before,
.slick-prev:before,
.slick-next:before,
input[type='checkbox'] ~ label:before,
input[type='checkbox'] ~ .label:before,
input[type='checkbox']:checked ~ label:before,
input[type='checkbox']:checked ~ .label:before,
input[type='radio'] ~ label:before,
input[type='radio'] ~ .label:before,
input[type='radio']:checked ~ label:before,
input[type='radio']:checked ~ .label:before,
.bobbis-kit .product-grid__item:nth-child(1):after,
.bobbis-kit .product-grid__item:nth-child(2):after,
.responsive-tabs.responsive-tabs--how_to_use .resp-arrow:before,
.quickshop-wrapper.active:before,
.quickshop__close:before,
.foundation-finder__find-match:after,
.foundation-finder__hide:after,
.foundation-finder__quiz:before,
.foundation-finder__chat__copy:before,
.product-palette:before,
.product-sku-quickview__inner:before,
.offer-specific__container .offer__breadcrumb-link:before,
.makeup-lesson__step .icon-arrow:before,
.artist-picks-page__products .recommended-actions a:before,
.chat-history-page__products .recommended-actions a:before,
.favorites-mylists__lists-create-button:before,
.favorite-actions__link--add:before,
.favorite-actions__link--remove:before,
.my-lists__list-link--print:before,
.my-lists__list-link--email:before,
.giftcard__guides-header:before {
  text-rendering: auto !important;
}

.artist-picks-page .transcript__header.expanded a.view-details-button:before,
.chat-history-page .transcript__header.expanded a.view-details-button:before {
  text-rendering: auto !important;
}

.artist-picks-page .transcript__header--view-details .view-details-button:before,
.chat-history-page .transcript__header--view-details .view-details-button:before {
  text-rendering: auto !important;
}

.artist-picks-page .transcript.expanded a.view-details-button:before,
.artist-picks-page .recommended-products__category.expanded a.view-details-button:before,
.chat-history-page .transcript.expanded a.view-details-button:before,
.chat-history-page .recommended-products__category.expanded a.view-details-button:before {
  text-rendering: auto !important;
}

.artist-picks-page
  .transcript
  .recommended-products__header--view-details
  .view-details-button:before,
.artist-picks-page
  .recommended-products__category
  .recommended-products__header--view-details
  .view-details-button:before,
.chat-history-page
  .transcript
  .recommended-products__header--view-details
  .view-details-button:before,
.chat-history-page
  .recommended-products__category
  .recommended-products__header--view-details
  .view-details-button:before {
  text-rendering: auto !important;
}

.icon-pinterest {
  display: none;
}

.site-footer__column {
  position: relative;
  .site-footer-social-links {
    .site-footer-social-links__item {
      position: relative;
      .icon-pinterest {
        display: inline-block;
      }
      .icon-pinterest:before,
      .icon-google:before {
        display: none;
      }
      a.icon.icon-pinterest {
        background-image: url('/media/images/global/Kakao_black.jpg');
        height: 22px;
        width: 22px;
        &:hover {
          background-image: url('/media/images/global/Kakao_pink.jpg');
          height: 22px;
          width: 22px;
        }
      }
      /* a.icon.icon-kakaoplus {
        background-image: url("/media/images/global/Kakaoplus_black.jpg");
        height: 24px;
        width: 24px;
        &:hover {
          background-image: url("/media/images/global/Kakaoplus_pink.jpg");
          height: 24px;
          width: 24px;
        }
      } */
      a.icon.icon-google {
        background-image: url('/media/images/global/Naver_black.jpg');
        height: 22px;
        width: 22px;
        &:hover {
          background-image: url('/media/images/global/Naver_pink.jpg');
          height: 22px;
          width: 22px;
        }
      }
      &:nth-child(2) {
        left: -2px;
        .icon-twitter {
          display: none;
        }
      }
      &:nth-child(3) {
        left: 107px;
      }
      &:nth-child(4) {
        left: -9px;
        top: -3px;
      }
      &:nth-child(5) {
        left: -122px;
      }
    }
  }
}

@media (max-width: 767px) {
  .giftcard-balance__purchase:after {
    text-rendering: auto !important;
  }
  .my-lists__list-header-link:after {
    text-rendering: auto !important;
  }
  .giftcard__check-balance:after {
    text-rendering: auto !important;
  }
}

@media (min-width: 768px) {
  .my-lists__list-header-link:before {
    text-rendering: auto !important;
  }
  .my-lists__list-link--share:before {
    text-rendering: auto !important;
  }
  .past-purchases-data-item__item.view-details .view-details-button:before {
    text-rendering: auto !important;
  }
  .active .past-purchases-data-item__item.view-details .view-details-button:before {
    text-rendering: auto !important;
  }
}

.icon-twitter {
  display: none;
}

.spp__share .product__social a {
  float: right;
}

.section-membership-benefit-m {
  .bobbi-pro__hero {
    height: auto;
    padding: 22% 0;
    background-position: 0 0;
    background-size: 100%;
  }
}
/* Container */
#colorbox {
  &.zdc_popup {
    height: 380px !important;
    margin-top: 15em;
  }
  .zdc {
    &__popup {
      &--title {
        float: none !important;
      }
    }
  }
  #cboxContent {
    #cboxLoadedContent {
      input {
        display: block;
        float: none;
        font-family: inherit;
        font-size: inherit;
        height: inherit;
        margin: 5px 15px 10px 0;
        position: relative;
        text-transform: inherit;
      }
      select {
        display: block;
        float: none;
        font-family: inherit;
        font-size: inherit;
        height: inherit;
        margin: 5px 15px 10px 0;
        position: relative;
        text-transform: inherit;
      }
      input.input_postal_code {
        display: inline;
        margin: 4px 0 0 0;
        vertical-align: middle;
      }
      input.input_default {
        display: inline;
      }
      fieldset.fs {
        div.form_element.radio {
          input {
            display: inline;
            margin-right: 10px;
            width: 0;
          }
        }
      }
      .required-message {
        clear: both;
        margin-bottom: 10px;
      }
      .address-form__item {
        padding: 0;
      }
      #address-form {
        float: left;
        width: 270px;
        fieldset.address {
          div.phone_part {
            margin-top: 5px;
          }
        }
        h2 {
          margin: 2px 0 2px 0;
          font-size: 20px !important;
        }
        width: 100%;
        padding: 10px 10px 10px 10px;
      }
      h2 {
        float: left;
        clear: both;
      }
      fieldset.fs {
        .full_name_container {
          float: none;
        }
        .address1_container {
          float: none;
          clear: both;
        }
        .address2_container {
          float: none;
        }
        .address3_container {
          float: none;
        }
        div.phone_part_area_code {
          select {
            width: 100% !important;
            float: left;
          }
          width: 35%;
          margin-top: 5px;
        }
        div.form_element {
          input {
            width: 100%;
          }
          margin: 0 0 0 0;
          width: 100%;
        }
        width: 100%;
        margin: 5px 0 5px 0;
        padding: 0 0 0 0;
      }
      fieldset.address {
        div.phone_part {
          input {
            width: 90% !important;
          }
          margin-right: 5px !important;
        }
        #profile-postal {
          input {
            width: 65%;
          }
          width: 100%;
        }
      }
      .submit_btn {
        margin: 0 10px 0 10px;
      }
      .error_messages {
        width: 100%;
        clear: both;
        color: #000;
        padding: 30px 50px;
        display: none;
      }
      .grey-button {
        display: block;
        text-align: center;
        width: auto;
        background-color: #999;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        height: 30px;
        line-height: 30px;
        margin: 5px 0 3px 0;
      }
      font-size: 11px;
      padding: 0;
      #address-lookup-container {
        .rna-list-header {
          li {
            width: 99.5%;
            display: block;
            float: left;
            border: 1px solid #f6f0e6;
            &:first {
              background-color: #ddd9c3;
            }
          }
          header1 {
            width: 73.7%;
            float: left;
            text-align: center;
            padding: 10px 0 10px 0;
          }
          header2 {
            float: left;
            text-align: center;
            padding: 10px 20px 10px 20px;
            border-left: 1px solid #f6f0e6;
          }
          display: none;
          text-align: center;
          font-weight: bold;
          font-size: 13px;
        }
        #rna-loading {
          z-index: 10000;
          position: fixed;
          top: 250px;
          right: 145px;
          display: none;
        }
        .address {
          input.town-submit-btn {
            width: auto !important;
            margin: 5px 0 0 0;
          }
          padding: 5px 5px 5px 5px;
          border: none;
        }
        .address-search {
          border-bottom: 1px solid #000;
          color: #000;
          margin: 0 5px 0 5px;
          padding: 0 0 5px 0;
          width: 97%;
          #rna_keyword {
            float: left;
            width: 78%;
            margin: 0 5px 0 0;
          }
          h4 {
            font-size: 18px;
            margin-bottom: 10px;
          }
          .keyword-hint {
            padding: 5px 0;
            font-weight: normal;
            clear: both;
            border: none;
          }
          h3 {
            font-size: 11px;
            color: #000;
            font-weight: bold;
            border: none;
            margin: 0;
          }
        }
        #rna-page-list {
          float: left;
          margin: 0 0 0 0;
          padding: 10px 0 10px 0;
          width: 100%;
          text-align: center;
        }
        .error-msg {
          display: none;
          margin: 0 auto 0 auto;
          width: 35%;
          padding: 30px 0 30px 0;
        }
        .error-display {
          width: 95%;
          background-color: #f7f7f7;
          margin: 10px 10px 10px 10px;
        }
        .error_messages.error {
          margin: 5px 0 0 10px;
        }
        .address1 {
          display: block;
          padding: 5px 0 5px 0;
          cursor: pointer;
        }
        .address3 {
          cursor: pointer;
        }
        .rna-list {
          display: none;
          border: none;
          margin: 0 0 0 0;
          width: 100%;
          tbody {
            max-height: 215px;
            overflow-y: auto;
            overflow-x: hidden;
            display: inline-block;
          }
          .sno {
            width: 14px;
          }
          th.full-address {
            text-align: center;
          }
          .full-address {
            width: 500px;
          }
          .postal {
            width: 140px;
          }
          th {
            line-height: 15px;
            height: 15px;
          }
          tr {
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            display: block;
          }
          .roadAddrPart1 {
            display: none;
          }
          .roadAddrPart2 {
            display: none;
          }
        }
        #address-hint-container {
          h4 {
            padding-left: 10px;
            font-weight: bold;
            margin: 10px 0 5px 0;
            color: #000;
          }
          background-color: #fff;
          padding-top: 5px;
          .hint-table {
            width: 95%;
            border-collapse: collapse;
            margin: 0 10px 0 10px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding: 10px 10px 10px 10px;
            font-size: 10px;
            .hint-table-header {
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              padding: 10px 10px 10px 10px;
              font-size: 10px;
              background-color: #f7f7f7;
            }
            .hint-table-data {
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              padding: 10px 10px 10px 10px;
              font-size: 10px;
            }
          }
          .building-name-registered {
            padding: 10px 10px 10px 10px;
            margin: 0 0 0 0;
          }
        }
        #address-results-container {
          background-color: #fff;
          padding-top: 5px;
          display: none;
          .rna-list {
            border-collapse: collapse;
            margin: 0 10px 0 10px;
            border: none !important;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            font-size: 10px;
            th {
              border: none !important;
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              padding: 10px 10px 10px 10px;
              font-size: 10px;
              background-color: #f7f7f7;
            }
            td {
              border: none !important;
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              padding: 10px 10px 10px 10px;
              font-size: 10px;
            }
          }
          zipcode {
            float: right;
          }
          .search-results-header {
            padding-left: 10px;
            color: #000;
            font-weight: bold;
            margin-left: 10px;
          }
        }
        #address-third-elements {
          .zip {
            display: none;
          }
        }
        #address-input-container {
          h4 {
            font-size: 14px;
            padding: 0 0 5px 10px;
            color: #000;
            font-weight: bold;
          }
          label {
            display: block;
            float: left;
            width: 25%;
            font-weight: bold;
          }
          .building {
            margin: 0 0 0 27%;
            .green-btn.small1.check-balance {
              margin: 10px 55px 10px 55px;
              float: left;
            }
          }
          ra3 {
            padding: 5px 0 5px 0;
            display: block;
          }
          .address-search-h {
            margin: 5px 0 5px 0;
          }
          #addr2_input_node {
            width: 75%;
          }
          .street {
            margin: 10px 10px 10px 10px;
          }
          .detail-address {
            margin: 10px 10px 10px 10px;
          }
          .address-details {
            background-color: #f7f7f7;
            padding: 10px 10px 10px 10px;
            margin: 0 10px 0 10px;
          }
          .address-submit-button {
            padding: 10px 10px 10px 10px;
            width: 100%;
            .address-input-submit {
              margin: 0 auto 0 auto;
              width: 60%;
              height: 30px;
              line-height: 14px;
            }
          }
        }
      }
      #address-confirmation {
        padding: 20px;
        width: 100%;
        text-align: center;
        h2 {
          font-size: 16px;
          color: #000;
          margin: 0 0 0 0;
          text-align: center;
          float: none;
        }
        .address-confirm-buttons {
          margin: 10px 0 0 30px;
          .address-confirm {
            width: 35%;
            float: left;
            .update-confirm {
              width: 100%;
            }
          }
          .address-cancel {
            float: left;
            width: 50%;
            margin-left: 5px;
            .grey-button {
              font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
              background-color: #999;
              text-transform: uppercase;
              color: #ccc;
              line-height: 1.5;
              font-size: 0.9em;
              text-align: center;
              letter-spacing: 0;
              border: 0;
              padding: 4px 10px 3px 10px;
              display: inline-block;
              zoom: 1;
            }
            .update-cancel {
              height: 32px;
              width: 100%;
            }
          }
        }
        .change-address-content {
          width: 100%;
          padding: 10px 0 10px 0;
          line-height: 18px;
          margin: 0 auto 0 auto;
          clear: both;
        }
      }
    }
    #cboxClose {
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}

.device-mobile {
  padding-top: 0px !important;
}

.sign-in-page,
.profile-page {
  .pc_email_promo,
  .sms_promo,
  .postal_promo,
  .promotions_label {
    margin: 10px 0;
    .label.error,
    .label_error {
      color: red;
    }
  }
}

#colorbox {
  &.product-fave-overlay-cbox {
    min-height: 304px;
    height: auto !important;
  }
  &.disconnect-overlay-launch {
    height: 180px !important;
    top: 1800px !important;
  }
}

.content {
  .sticky-offer {
    background: #ff4661 !important;
  }
}

.tiny-waitlist-overlay {
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
  .email_input {
    display: inline-block;
    input.error {
      border: 1px solid red;
    }
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .privacy_text {
    .label.error {
      color: red;
    }
  }
}

.waitlist_thankyou_message {
  margin: 90px 60px;
  font-family: 'Brandon Text Bold';
}

.product__notify-me {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  .product__button {
    height: 100%;
    width: 100%;
  }
}
/* Power reviews */
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-rd-helpful-action {
            .pr-helpful-yes {
              .pr-helpful-count:before {
                content: '예';
              }
            }
            .pr-helpful-no {
              .pr-helpful-count:before {
                content: '아니오';
              }
            }
          }
        }
        p,
        span,
        time,
        a {
          @include font-gothic;
        }
      }
    }
  }
}

.product-full {
  &__container {
    .product-full__data-heading-label {
      width: 25%;
    }
    .product-full__data-heading-toggle {
      right: 14px;
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          a,
          span {
            @include font-gothic;
          }
        }
      }
    }
  }
}
/******************
*  media queries  *
*******************/

/* MOBILE */
@media all and (max-width: 764px) {
  .site-content > .block {
    position: relative !important;
  }
  .game-title h2 {
    display: inline-block;
    margin-bottom: 0 !important;
  }
  #roulette-wrapper h2 {
    font-size: 24px !important;
  }
  #roulette-wrapper > #roulette-copy-button-container.mobile-message {
    position: absolute;
    background: #fff;
    z-index: 4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    border: 8px solid #ff4661;
  }
  #roulette-wrapper .message {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: table-cell;
  }
}

@media all and (max-width: 990px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none !important;
  }
  #roulette-wrapper {
    width: 100%;
    padding-bottom: 20px;
  }
  #roulette-wrapper > div {
    width: 100%;
    display: block;
    position: relative;
  }
  #roulette-wrapper > #roulette-wheel-container {
    margin-bottom: -130px;
    margin-top: 20px;
  }
  #roulette-wrapper > #roulette-wheel-container > #roulette-pointer {
    top: -45px;
    left: 50%;
    margin-left: -20px;
  }
  #roulette-wrapper > #roulette-copy-button-container {
    padding: 0;
    margin-bottom: 30px;
  }
  #roulette-wrapper .game-rules.mobile {
    background: #fff;
    margin-top: 60px;
    padding-top: 45px;
    min-height: 250px;
  }
  #roulette-wrapper .message .game-rules {
    margin-top: 20px;
  }
  #roulette-wrapper > #roulette-copy-button-container {
    padding: 20px;
  }
  .terms p {
    width: 90%;
    max-width: none;
  }
  #roulette-game-loading h2 {
    margin-top: 40px;
  }
  #roulette-wrapper > #roulette-wheel-container > .roulette-wheel {
    top: -30px;
  }
  #roulette-wrapper > #roulette-wheel-container > .roulette-wheel > img {
    width: 100%;
    max-width: 500px;
  }
}

@media all and (min-width: 764px) {
  #roulette-wrapper h2.first-line-title {
    font-size: 70px;
    margin-top: 10px;
  }
  #roulette-wrapper h2.second-line-title {
    font-size: 60px;
  }
}
/* DESKTOP */
@media all and (min-width: 990px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  #roulette-wrapper {
    width: 100%;
    margin: 60px 0 80px 0;
    height: 458px;
    min-width: 990px;
    max-width: 1024px;
    border: 4px solid #ff4661;
  }
  #roulette-wrapper .main-copy p,
  #roulette-wrapper #spin-again-message p,
  #roulette-wrapper .game-unavailable p {
    font-size: 18px;
    line-height: 1.45em;
  }
  #roulette-wrapper .main-copy p,
  #roulette-wrapper #spin-again-message p {
    height: 90px;
    padding: 10px 0 20px 0;
  }
  #roulette-wrapper > div {
    width: 50%;
    float: left;
    height: 100%;
  }
  #roulette-wrapper .message .game-rules {
    bottom: 0;
  }
  #roulette-wrapper .message .game-rules,
  #roulette-wrapper > #roulette-copy-button-container .game-rules.desktop {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
  }
  #roulette-wrapper > #roulette-copy-button-container .game-rules.desktop {
    bottom: 0;
  }
  #roulette-wrapper > #roulette-wheel-container > #roulette-pointer {
    top: -50px;
    left: 196px;
  }
  #roulette-wrapper > #roulette-copy-button-container {
    padding: 20px;
  }
  #roulette-wrapper > #roulette-copy-button-container > .game-unavailable:not(.hide),
  #roulette-wrapper > #roulette-copy-button-container > #roulette-game-loading {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    height: 100%;
  }
  #roulette-wrapper > #roulette-wheel-container > .roulette-wheel {
    width: 540px;
    height: 540px;
    margin-top: -40px;
    margin-left: -50px;
  }
  #roulette-wrapper p {
    width: 400px;
  }
  .terms p {
    width: 80% !important;
    max-width: 600px;
  }
  #roulette-wrapper h2 {
    font-size: 40px;
  }
}

#roulette-wrapper p {
  margin: 10px auto;
}

#roulette-wrapper .win-msg-product-copy p {
  margin: 5px auto !important;
}

#roulette-wrapper button {
  padding: 10px;
  width: 200px;
  margin: 10px 0;
  background: #ff4661;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 3;
  height: auto;
  line-height: 14px;
}

#roulette-wrapper h2.prize {
  margin-top: 60px;
}

#roulette-wrapper h2 {
  color: #c1100a;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 35px;
  font-family: 'Lato Black';
  margin-bottom: 20px;
}

#roulette-wrapper {
  text-align: center;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

#roulette-wrapper > #roulette-wheel-container {
  background: #ff4661;
}

#roulette-wrapper > div {
  box-sizing: border-box;
  position: relative;
}

#roulette-wrapper > #roulette-copy-button-container {
  display: table;
}

#roulette-wrapper > #roulette-copy-button-container p {
  margin-left: auto;
  margin-right: auto;
}

#roulette-wrapper > .message p {
  line-height: 20px;
}

.terms p {
  font-size: 11px;
  line-height: 14px !important;
  margin-top: 20px;
}

#roulette-wrapper p.spin-remaining,
#roulette-wrapper p.spins-remaining {
  color: #ff4661;
  font-size: 14px;
  text-transform: uppercase;
}

#roulette-wrapper .play-button.disabled {
  background: #ccc;
  cursor: default;
}

#roulette-wrapper .product-image {
  height: 120px;
  width: auto;
  display: block;
  margin: 0 auto;
}

@-webkit-keyframes left-right {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}

@keyframes left-right {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}

#roulette-wrapper > #roulette-wheel-container > #roulette-pointer {
  z-index: 2;
  position: absolute;
}

#roulette-wrapper > #roulette-wheel-container > #roulette-pointer.left-right {
  -webkit-animation: left-right 0.2s;
  -moz-animation: left-right 0.2s;
  -ms-animation: left-right 0.2s;
  -o-animation: left-right 0.2s;
  animation: left-right 0.2s;
  -webkit-animation-iteration-count: 18;
  animation-iteration-count: 18;
  -webkit-animation-timing-function: ease-out; /* Safari 4.0 - 8.0 */
  animation-timing-function: ease-out;
}

#roulette-wrapper > #roulette-wheel-container > .roulette-wheel {
  z-index: 1;
  position: relative;
  -ms-transform: rotate(35.6deg);
  -webkit-transform: rotate(35.6deg);
  transform: rotate(35.6deg);
}

#roulette-wrapper > #roulette-wheel-container > .roulette-wheel.spin_animate {
  -webkit-transition: -webkit-transform 4s ease-out;
  -moz-transition: -moz-transform 4s ease-out;
  -o-transition: -o-transform 4s ease-out;
  transition: transform 4s ease-out;
}

/******************
* FIRST SPIN WINS *
*******************/

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_set {
  -webkit-transform: rotate(1009.6deg);
  -moz-transform: rotate(1009.6deg);
  transform: rotate(1009.6deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_product2 {
  -webkit-transform: rotate(938deg);
  -moz-transform: rotate(938deg);
  transform: rotate(938deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_product {
  -webkit-transform: rotate(1152.6deg);
  -moz-transform: rotate(1152.6deg);
  transform: rotate(1152.6deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_set2 {
  -webkit-transform: rotate(1080deg);
  -moz-transform: rotate(1080deg);
  transform: rotate(1080deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_product3 {
  -webkit-transform: rotate(938deg);
  -moz-transform: rotate(938deg);
  transform: rotate(868deg) !important;
}

.primer-plus {
  .block_plus {
    .product-collection-product__product .product-collection-product__product-name {
      font-weight: normal;
      font-size: 14px;
      top: 182px;
      line-height: 1em;
      .product-collection-product__price {
        top: 52px;
      }
    }
    .product-image-qs__product .product-image-qs__product-name {
      font-weight: normal;
      font-size: 14px;
      line-height: 1em;
      top: 178px;
    }
    .product-image-qs__product .product-image-qs_link_container .product-image-qs_cta {
      width: 86px;
      height: 31px;
    }
    .product-image-qs__product .product__price {
      top: 228px;
    }
  }
  .block_product {
    .product-collection-product__product-name {
      line-height: 1.5em;
    }
  }
}

.adpl {
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'],
  select {
    &:required {
      &.js-placeholder-mode,
      &.js-label-mode {
        & + label:before {
          content: '* ' attr(placeholder);
        }
      }
    }
  }
}

.site-header {
  .site-utils__menu {
    .site-utils__item--cart {
      .cart_overlay {
        .cart-confirm-wrapper {
          .cart-products {
            .prod {
              .qty {
                font-size: 11px;
              }
              .price {
                width: 22%;
                font-size: 11px;
              }
              .prod-info {
                width: 45%;
              }
            }
          }
        }
      }
    }
  }
}

.book-appt-container {
  .registration__terms {
    &.error {
      color: $color-red;
    }
  }
}

.homepage-hero__inner {
  background-color: $color-white;
}

.basic-carousel-formatter--overlap-style {
  .basic-carousel__container {
    .carousel-dots {
      @include breakpoint($small-down) {
        top: 77%;
      }
      @include breakpoint($xsmall-down) {
        top: 455px;
      }
      @include breakpoint($xxsmall-down) {
        top: 415px;
      }
      @include breakpoint($small-screen) {
        top: 360px;
      }
    }
  }
}

textarea {
  &.error {
    border: 1px solid $color-red;
  }
}

.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-full__add-button {
        height: auto;
      }
    }
  }
}
