#pr-reviewdisplay .p-w-r {
  .pr-review-snapshot {
    .pr-review-snapshot-content-block,
    .pr-review-snapshot-snippets-headline {
      display: none;
    }
    .pr-review-snapshot-block-container {
      width: 100%;
      margin: 4% 0;
      display: grid;
      @include breakpoint($portrait-up) {
        width: 100%;
        grid-template-columns: 25%;
        margin: 20px 0;
      }
    }
    .pr-review-snapshot-block {
      border: none;
    }
    .pr-review-snapshot-block-snippet {
      grid-column: 1;
      grid-row: 1;
      padding: 0;
      width: fit-content;
      align-self: baseline;
      .pr-snippet {
        .pr-accessible-text {
          text-align: #{$ldirection};
        }
        .pr-snippet-read-and-write {
          position: absolute;
          #{$ldirection}: 13%;
          bottom: 10%;
          width: 60%;
          @include breakpoint($portrait-up) {
            width: 17%;
            #{$ldirection}: auto;
            #{$rdirection}: 8%;
            bottom: 11%;
            top: auto;
            overflow: hidden;
            line-height: 40px;
          }
          .pr-snippet-write-review-link {
            font-weight: normal;
          }
        }
      }
    }
    .pr-review-snapshot-block-recommend {
      grid-column: 1;
      grid-row: 1;
      padding: 0;
      position: absolute;
      margin: 10px 0;
      top: 0;
      #{$rdirection}: 0;
      width: fit-content;
      align-self: baseline;
      @include breakpoint($portrait-up) {
        top: 80px;
        #{$ldirection}: 0;
      }
      span {
        padding: 0;
      }
      .pr-snippet-reco-to-friend {
        position: relative;
        .pr-snippet-reco-to-friend-percent {
          .pr-checkbox-icon {
            svg {
              display: none;
            }
          }
        }
        .pr-snippet-reco-to-friend-green {
          background-color: $color-white;
          position: absolute;
          #{$ldirection}: 0;
          top: 5px;
          .pr-reco-green {
            padding: 0;
            .pr-reco-value {
              font-weight: 700;
              line-height: normal;
            }
          }
        }
      }
      .pr-reco-to-friend-message {
        max-width: 110px;
        text-indent: 47px;
        text-align: #{$ldirection};
        padding: 8px 0;
        @include breakpoint($portrait-up) {
          max-width: 100px;
          padding: 6px 0;
        }
      }
    }
    .pr-review-snapshot-block-histogram {
      grid-column: 1;
      grid-row: 2;
      padding: 0;
      margin: 10% 0 40%;
      @include breakpoint($portrait-up) {
        grid-column: 2;
        grid-row: 1;
        margin: 0;
      }
    }
  }
}

.write_a_review {
  &__container {
    #pr-write {
      .pr-subscript {
        display: none;
      }
    }
  }
}

.product-brief {
  .pr-category-snippet {
    &__total {
      font-family: $bbtext-regular !important;
    }
  }
}

body.page-product-110333 {
  .elc-ugc-v1 {
    display: none;
  }
}
