$color-tamarillo: #a81010;
$color-border-legal-statement: rgb(238, 238, 238);
$ab-small-down: max-width 768px;

@mixin bg-position($x, $y) {
  @if $text-direction == ltr {
    background-position: $x $y;
  } @else {
    background-position: 100% - $x $y;
  }
}

.info-link {
  text-align: left;
}

.appt-book {
  #appointment-book-sections {
    .appointment-booking-location-select {
      .submit_geoloc__submit {
        width: calc(97% - 79px);
      }
    }
    .error {
      color: $color-red;
    }
    .appointment-booking-review__account-signup-gdpr-compliant {
      .gdpr_compliant__info-right-icon {
        margin-left: 10px;
        overflow: hidden;
        width: 30px;
        height: 30px;
      }
      .gdpr_compliant__info-for-left,
      .gdpr_compliant__info-right-icon {
        float: left;
      }
    }
    .js-no-last-name {
      display: block !important;
      .signup__last-name {
        display: none;
      }
      .signup__first-name,
      .signup__email {
        width: 100%;
        color: $color-black;
      }
      .signup__appt_kr_phone_code {
        float: $ldirection;
        height: 40px;
        @include breakpoint($ab-small-down) {
          min-width: 75px;
        }
      }
      .signup__appt_mobile_phone_separator {
        width: 5%;
        float: $ldirection;
        margin-top: 3%;
        display: inline-block;
        @include breakpoint($ab-small-down) {
          margin-top: 2%;
        }
      }
      .signup__appt_mobile_part1 {
        float: $ldirection;
        display: inline-block;
      }
      .signup__appt_mobile_part2 {
        float: $rdirection;
        display: inline-block;
      }
    }
    .appointment-booking-review__account-signup-form {
      clear: left;
      .signup__legal_statement {
        border: 1px solid $color-border-legal-statement;
        margin: 16px 0;
        overflow-y: scroll;
        height: 150px;
      }
      .js-accept-terms-head,
      .signup__legal_statement,
      .radio_group,
      .signup__accept-privacy-head,
      .js-accept-thirdparty-head {
        text-align: left;
      }
    }
    .appointment-booking-review__error-messages .error-message,
    .appointment-booking-review__error-messages .error-message a {
      color: $color-tamarillo;
      margin: 0.5em 0;
      font-weight: bold;
    }
    .date-time .date-time__hour,
    #confirmation .appt-time {
      display: inline;
      word-spacing: -3px;
    }
    #confirmation {
      .appt-date {
        display: inline;
      }
    }
  }
}

.touchevents {
  .appt-book {
    .location-form {
      &__select {
        @include bg-position(97%, 46%);
      }
    }
  }
}
